import clsx from 'clsx';
import useSliderPagination from 'src/hooks/use-slider-pagination';

type SliderPaginationProps = {
  items: unknown[];
};

export default function SliderPagination({ items }: SliderPaginationProps) {
  const { activeIndex, slideToIndex } = useSliderPagination();

  return (
    <div className="relative flex justify-center space-x-1.5">
      {items.map((_item, index) => {
        const isActive = index === activeIndex;
        const ariaProps = isActive ? { 'aria-current': true } : {};
        return (
          <button
            key={index}
            onClick={() => slideToIndex(index)}
            className={clsx('px-0.5 py-3 focus:opacity-50', isActive ? 'opacity-100 focus:opacity-100' : 'opacity-25')}
            {...ariaProps}
          >
            <span className="block w-5 border-b border-brandYellowC1" aria-hidden="true" />
            <span className="sr-only">{index + 1}</span>
          </button>
        );
      })}
    </div>
  );
}
