import useSWR, { SWRResponse } from 'swr';
import useGeoInfo from 'src/hooks/use-geo-info';
import useSdk, { CuratedContentSlug, CuratedContentQuery, ApiError } from 'src/hooks/use-sdk';
import useSession from 'src/hooks/use-session';
import { isTabletOrDesktop } from 'src/utilities/capabilities';
import { isContentAvailable } from 'src/utilities/content-availability-helpers';

/**
 * The default `sliderItemsFirst` value in `curatedContent.query.gql` is 6, which is enough to visually render enough
 * items per slider on server-side render while keeping the initial page load size small by reducing the size of
 * `__NEXT_DATA__`. The `useCuratedContent` hook will use SWR to fetch more items for each slider on the client-side.
 *
 * On larger breakpoints we want to fetch more items per slider to fill the available space. This number of items
 * should be divisible by 3, 4, and 6 for best results with the 12-column teaser grid layout.
 */
const desktopSliderItemsFirst = 24;

/**
 * Fetches curated content query from the API and filters out unavailable nodes
 * Used on main content pages like the `/discover`, `/search`, and `/live` page
 *
 * @param slug The slug of the curated content
 * @param fallbackData The initial data to use while fetching, used for SSR
 * @returns A `stageSliderItems` and `contentContainerItems` array of available nodes and an `error` if any
 */
export function useCuratedContent(
  slug: CuratedContentSlug,
  fallbackData: CuratedContentQuery,
): SWRResponse<CuratedContentQuery, ApiError> {
  const sdk = useSdk();
  const { data: sessionData, isLoading: sessionIsLoading } = useSession();
  const { data: geoInfoData, isLoading: geoInfoIsLoading } = useGeoInfo();
  // Fetch more items for each slider on larger breakpoints, otherwise load the default amount specified in the query
  const sliderItemsFirst = isTabletOrDesktop() ? desktopSliderItemsFirst : undefined;
  // Unique key for the SWR cache based on the slug and current user id because the content can be personalized
  const swrCacheKey =
    sessionIsLoading || geoInfoIsLoading
      ? undefined
      : ['curatedContent', slug, geoInfoData?.countryCode, sessionData?.currentUser?.id].filter(Boolean).join('/');

  return useSWR<CuratedContentQuery, ApiError>(
    swrCacheKey,
    async () => {
      const response = await sdk.curatedContent({ slug, sliderItemsFirst });

      // Filter out any unavailable nodes based on geo-blocking rules and publish dates
      const stageSlider = response.curatedContent.stageSlider.filter(({ item }) =>
        isContentAvailable(item, { countryCode: geoInfoData?.countryCode }),
      );
      const curatedContent = response.curatedContent.curatedContent.map((curatedContentItem) => {
        if (curatedContentItem.__typename === 'ListSlider') {
          return {
            ...curatedContentItem,
            items: curatedContentItem.items.filter((item) =>
              isContentAvailable(item, { countryCode: geoInfoData?.countryCode }),
            ),
          };
        }
        return curatedContentItem;
      });

      return {
        ...response,
        curatedContent: {
          ...response.curatedContent,
          stageSlider,
          curatedContent,
        },
      };
    },
    { fallbackData },
  );
}
