import { ComponentProps, useEffect, useState } from 'react';
import Link from 'next/link';
import { Swiper as SwiperClass } from 'swiper';
import { PictureType, StageItemV2 } from 'generated/graphql';
import ImageSquare from 'src/components/common/image-square';
import { useSlider } from 'src/components/common/slider';
import LiveConcertCountdown from 'src/components/live-concert/live-concert-countdown';
import LiveConcertLabel from 'src/components/live-concert/live-concert-label';
import LiveConcertRerunList from 'src/components/live-concert/live-concert-rerun-list';
import { NodeTeaserBadge } from 'src/components/node/node-teaser-badge';
import StageSliderItemContent from 'src/components/stage-slider/stage-slider-item-content';
import StageSliderItemImage from 'src/components/stage-slider/stage-slider-item-image';
import StageSliderItemLabel from 'src/components/stage-slider/stage-slider-item-label';
import StageSliderItemTitle from 'src/components/stage-slider/stage-slider-item-title';
import { getPictureByType } from 'src/utilities/image-helpers';
import { getNodePath } from 'src/utilities/url-helpers';

type StageSliderItemProps = {
  /**
   * The index of the slider item - used to determine the initial slide and optimize image preloading
   */
  index: number;
  stageSliderItem: Pick<StageItemV2, 'title' | 'subtitle' | 'label'> & {
    item:
      | Pick<Extract<StageItemV2['item'], { __typename: 'Album' }>, '__typename' | 'id' | 'pictures' | 'title'>
      | Pick<Extract<StageItemV2['item'], { __typename: 'Artist' }>, '__typename' | 'id' | 'pictures' | 'name'>
      | Pick<Extract<StageItemV2['item'], { __typename: 'Group' }>, '__typename' | 'id' | 'pictures' | 'name'>
      | Pick<
          Extract<StageItemV2['item'], { __typename: 'LiveConcert' }>,
          | '__typename'
          | 'id'
          | 'pictures'
          | 'publicationLevel'
          | 'typeDisplayName'
          | 'finalEndTime'
          | 'reruns'
          | 'startTime'
          | 'endTime'
          | 'streamStartTime'
          | 'geoAccessCountries'
          | 'geoAccessMode'
          | 'plannedForVod'
          | 'title'
          | 'stream'
        >
      | Pick<Extract<StageItemV2['item'], { __typename: 'Partner' }>, '__typename' | 'id' | 'pictures' | 'name'>
      | Pick<
          Extract<StageItemV2['item'], { __typename: 'Video' }>,
          '__typename' | 'id' | 'pictures' | 'publicationLevel' | 'typeDisplayName' | 'title'
        >
      | Pick<
          Extract<StageItemV2['item'], { __typename: 'VodConcert' }>,
          '__typename' | 'id' | 'pictures' | 'publicationLevel' | 'typeDisplayName' | 'title'
        >;
  };
};

/**
 * Renders a stage slider item. The item can be an album, artist, group, partner, live concert, video, or vod concert.
 * Stage slider items are rendered differently depending on their type.
 */
export default function StageSliderItem({ stageSliderItem, index }: StageSliderItemProps) {
  const { title, subtitle, label, item } = stageSliderItem;
  const { slider } = useSlider();
  const href = getNodePath(item);

  // slider image loading optimizations
  const [imageProps, setImageProps] = useState<
    Pick<ComponentProps<typeof StageSliderItemImage>, 'loading' | 'priority'>
  >({
    // add `priority` to the first image to optimize Largest Contentful Paint (LCP)
    // this will preload the image via a link tag and change the loading strategy to eager instead of lazy
    priority: index === 0,
  });

  /**
   * Eagerly load the image in the next slide when the slider is swiped to the current slide.
   * This is accomplished by listening to the `activeIndexChange` event of the slider and comparing the current index with the index of the next slide.
   * If the next slide is the current slide, then the image in the next slide is loaded eagerly.
   */
  useEffect(() => {
    function handleSliderActiveIndexChange(slider: SwiperClass) {
      // eagerly load the image in the next slide so it is loaded when swiping to it
      if (slider.realIndex + 1 === index) {
        setImageProps({
          loading: 'eager',
        });
      }
    }

    if (slider) {
      handleSliderActiveIndexChange(slider);
      slider.on('activeIndexChange', handleSliderActiveIndexChange);
      return () => {
        slider.off('activeIndexChange', handleSliderActiveIndexChange);
      };
    }
  }, [index, slider]);

  return (
    <Link
      href={href}
      prefetch={false}
      data-test={`stage-slider-item:${item.id}`}
      className="group relative block h-[546px] select-none overflow-hidden md:h-auto"
    >
      {/* Album Stage */}
      {item.__typename === 'Album' && (
        <>
          <StageSliderItemImage pictures={item.pictures} pictureType={PictureType.Cover} alt="" {...imageProps} />
          <StageSliderItemContent>
            <StageSliderItemLabel>{label}</StageSliderItemLabel>
            <StageSliderItemTitle primary={title || item.title} secondary={subtitle} center />
          </StageSliderItemContent>
        </>
      )}
      {/* Artist or Group Stage */}
      {(item.__typename === 'Artist' || item.__typename === 'Group') && (
        <>
          <StageSliderItemImage pictures={item.pictures} pictureType={PictureType.Teaser} alt="" {...imageProps} />
          <StageSliderItemContent>
            <StageSliderItemLabel>{label}</StageSliderItemLabel>
            <StageSliderItemTitle primary={title || item.name} secondary={subtitle} center />
          </StageSliderItemContent>
        </>
      )}
      {/* Partner Stage */}
      {item.__typename === 'Partner' && (
        <>
          <StageSliderItemImage pictures={item.pictures} pictureType={PictureType.Teaser} alt="" {...imageProps} />
          <StageSliderItemContent>
            <div className="flex gap-x-4 md:gap-x-8">
              <ImageSquare
                src={getPictureByType(item.pictures, PictureType.Logo)?.url}
                alt={item.name}
                placeholder="partner"
                sizes={['(min-width: 480px) 120px', '64px'].join(', ')}
                className="size-16 shrink-0 overflow-hidden rounded-full md:size-[120]"
                fill
              />
              <div>
                <StageSliderItemLabel>{label}</StageSliderItemLabel>
                <StageSliderItemTitle primary={title || item.name} secondary={subtitle} />
              </div>
            </div>
          </StageSliderItemContent>
        </>
      )}
      {/* Video Stage (Live Concert, Video, or Vod Concert) */}
      {(item.__typename === 'LiveConcert' || item.__typename === 'Video' || item.__typename === 'VodConcert') && (
        <>
          <StageSliderItemImage pictures={item.pictures} pictureType={PictureType.Teaser} alt="" {...imageProps} />
          <div className="absolute left-6 top-6">
            <NodeTeaserBadge node={item} />
          </div>
          <StageSliderItemContent>
            {item.__typename === 'LiveConcert' && (
              <div className="absolute top-60 w-full text-center lg:top-9">
                <LiveConcertCountdown liveConcert={item} />
              </div>
            )}
            <StageSliderItemLabel>
              {item.__typename === 'LiveConcert' ? <LiveConcertLabel liveConcert={item} defaultLabel={label} /> : label}
            </StageSliderItemLabel>
            <StageSliderItemTitle primary={title || item.title} secondary={subtitle} maxLines={4} center />
            {item.__typename === 'LiveConcert' && (
              <div className="dg-text-regular-3 pt-6 text-center text-greyG2">
                <LiveConcertRerunList liveConcert={item} />
              </div>
            )}
          </StageSliderItemContent>
        </>
      )}
    </Link>
  );
}
