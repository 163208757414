import clsx from 'clsx';
import { LiveConcert } from 'generated/graphql';
import RenderClientOnly from 'src/components/render-client-only';
import Time from 'src/components/time';
import useLiveConcertState from 'src/hooks/use-live-concert-state';
import useTranslate from 'src/hooks/use-translate';

type LiveConcertRerunListProps = {
  liveConcert: Pick<LiveConcert, 'endTime' | 'startTime' | 'streamStartTime' | 'reruns'>;
  className?: string;
};

/**
 * Displays a numbered list of upcoming reruns for a LiveConcert based on the current timestamp
 * When no reruns are available, an empty object is returned
 *
 * @example
 * ```ts
 * <RerunList liveConcert={liveConcert} />
 * ```
 */
export default function LiveConcertRerunList({
  liveConcert,
  className = 'space-x-2 space-y-2 2xl:space-y-3',
}: LiveConcertRerunListProps) {
  const t = useTranslate();
  const { upcomingReruns } = useLiveConcertState(liveConcert);

  // Render this component on the client only because the upcoming reruns might have already passed
  return (
    <RenderClientOnly>
      {upcomingReruns.length === 0 ? (
        <></>
      ) : (
        <div data-test="live-concert-rerun-list" className={clsx('flex flex-col', className)}>
          {upcomingReruns.map((rerun, index) => (
            <div key={rerun.startTime}>
              <span>{`${index + 1}. ${t('component__rerun_default_label')}`}&nbsp;&ndash;&nbsp;</span>
              <Time value={rerun.startTime} style="shortTime" />
            </div>
          ))}
        </div>
      )}
    </RenderClientOnly>
  );
}
