import clsx from 'clsx';
import { useTitleClamp } from 'src/hooks/use-title-clamp';

/** Max number of combined lines that primary and secondary title may have */
const maxTitleLines = 5;

type StageTitleProps = {
  /**
   * The primary title. This title will always be visible.
   */
  primary: string;
  /**
   * Optional secondary title.
   */
  secondary?: string;
  /**
   * Whether the title should be centered.
   */
  center?: boolean;
  /**
   * Max number of lines for both primary and secondary title combined.
   * The primary title will take up a maximum of `maxLines - 1` lines.
   * The secondary title will take up the remaining number of lines.
   */
  maxLines?: number;
};

/**
 * Renders the title for a stage slider item component. The title consists of a primary and optionally a secondary title.
 * It automatically calculates the optimal number of lines for the primary and secondary title on component mount and will use an ellipsis if the title is too long.
 */
export default function StageSliderItemTitle({
  primary,
  secondary,
  center,
  maxLines = maxTitleLines,
}: StageTitleProps) {
  const { titleRef, titleClamp } = useTitleClamp<HTMLDivElement>(maxLines - 1);
  /** The secondary title takes up the remaining number of lines available */
  const secondaryTitleClamp = maxLines - titleClamp;

  if (maxLines < 1 || maxLines > maxTitleLines) {
    throw new Error(`Invalid maxLines value: ${maxLines}. Must be between 1 and ${maxTitleLines}`);
  }

  return (
    <>
      <div
        className={clsx(
          'dg-text-medium-1 text-white',
          titleClamp === 1 && 'line-clamp-1',
          titleClamp === 2 && 'line-clamp-2',
          titleClamp === 3 && 'line-clamp-3',
          titleClamp === 4 && 'line-clamp-4',
          center && 'text-center',
        )}
        ref={titleRef}
        data-test="stage-slider-item-title"
      >
        {primary}
      </div>
      {secondary && (
        <div
          className={clsx(
            'dg-text-light-1 text-white',
            secondaryTitleClamp === 1 && 'line-clamp-1',
            secondaryTitleClamp === 2 && 'line-clamp-2',
            secondaryTitleClamp === 3 && 'line-clamp-3',
            secondaryTitleClamp === 4 && 'line-clamp-4',
            center && 'text-center',
          )}
          data-test="stage-slider-item-secondary-title"
        >
          {secondary}
        </div>
      )}
    </>
  );
}
