import { ComponentProps } from 'react';
import { SliderProvider, Slider, SliderSlide } from 'src/components/common/slider';
import SliderPagination from 'src/components/common/slider-pagination';
import StageSliderItem from 'src/components/stage-slider/stage-slider-item';

type StageSliderProps = {
  items: ComponentProps<typeof StageSliderItem>['stageSliderItem'][];
};

export default function StageSlider({ items }: StageSliderProps) {
  // Stage sliders with only a single item won’t need autoplay or looping
  const hasSingleSliderItem = items.length === 1;
  const autoplay = hasSingleSliderItem ? false : { delay: 5000, pauseOnMouseEnter: true, disableOnInteraction: false };
  const loop = !hasSingleSliderItem;

  // Don’t render anything if there are no items
  if (items.length === 0) return null;

  return (
    <SliderProvider>
      <Slider
        options={{
          speed: 700,
          autoplay,
          loop,
        }}
        id="stage-slider"
        data-test="stage-slider"
        withNavigation
      >
        {items.map((item, index) => {
          return (
            <SliderSlide key={index}>
              <StageSliderItem stageSliderItem={item} index={index} />
            </SliderSlide>
          );
        })}
      </Slider>
      <div className="pt-8" data-test="stage-pagination">
        <SliderPagination items={items} />
      </div>
    </SliderProvider>
  );
}
