import { useCallback, useEffect, useMemo, useState } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { useSlider } from 'src/components/common/slider';

/**
 * Exposes pagination state of a slider within the current context.
 */
export default function useSliderPagination() {
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  const { slider } = useSlider();

  const slideToIndex = useCallback(
    (index: number) => {
      if (!slider) return;
      if (slider.params.loop) {
        slider.slideToLoop(index);
      } else {
        slider.slideTo(index);
      }
    },
    [slider],
  );

  useEffect(() => {
    if (!slider) return;
    function handleIndexChange(swiper: SwiperClass) {
      setActiveIndex(swiper.realIndex);
    }
    setActiveIndex(slider.realIndex);
    slider.on('activeIndexChange', handleIndexChange);
    return () => {
      slider.off('activeIndexChange', handleIndexChange);
    };
  }, [slider]);

  return useMemo(
    () => ({
      activeIndex,
      slideToIndex,
    }),
    [activeIndex, slideToIndex],
  );
}
